import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/welcome");
  };

  return (
    <footer>
      <div className="container container--bottom container-bg5 container-footer">
        <div className="container-content footer-container">
          <p>Erfassen · Bewerten · Planen · Gestalten</p>

          <h2>
            Freiraum- und Landschaftsplanung
            <br />
            Matthiesen&nbsp;·&nbsp;Schlegel
          </h2>

          <p>
            Allensteiner Weg 71
            <br />
            24161 Altenholz
          </p>
          <p>
            Telefon: 0431/32 22 54
            <br />
            E-Mail:
            <a href="mailto:info@matthiesen-schlegel.de">
              info(at)matthiesen-schlegel.de
            </a>
          </p>
        </div>
        <div className="footer-cta"></div>
      </div>

      <div className="container container-bg11">
        <div className="container-content">
          <ul className="footer-nav">
            <li className="footer-left">
              &copy;&nbsp;2022&nbsp;
              <a href="index.html">Matthiesen&nbsp;·&nbsp;Schlegel</a>
            </li>
            <li className="footer-right">
              <a href="impressum">Impressum</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
