import React from "react";

import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

const Header = (props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const goBack = () => {
    navigate("/welcome");
  };

  return (
    <header className="head container-bg1 paper">
      <div className="head-wrapper">
        <div className="head-content">
          <a href="index.html" className="head-logo"></a>

          <div className="head-deco"></div>
          <nav className="nav-container">
            <div className="menu-mainnav-container">
              <ul id="menu-mainnav" className="menu">
                <li
                  className={
                    props.active === "aboutus" ? "current-menu-item" : ""
                  }
                >
                  <a href="/">Über uns</a>
                </li>
                <li
                  className={
                    props.active === "services" ? "current-menu-item" : ""
                  }
                >
                  <a href="leistungen">Leistungen</a>
                </li>
                <li
                  className={
                    props.active === "projects" ? "current-menu-item" : ""
                  }
                >
                  <a href="projekte">Projekte</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="head-pullmenu"></div>
      </div>
    </header>
  );
};

export default React.memo(Header);
