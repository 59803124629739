import "babel-polyfill";

import { Route, BrowserRouter, Routes } from "react-router-dom";

import React from "react";
import styles from "../sass/styles.scss";

import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Imprint from "./pages/Imprint";
import Projects from "./pages/Projects";
import ProjectKronshagen from "./pages/ProjectKronshagen";
import ProjectFlintbek from "./pages/ProjectFlintbek";
import ProjectFlintbekSchule from "./pages/ProjectFlintbekSchule";
import ProjectSchlossgarten from "./pages/ProjectSchlossgarten";
import ProjectDRKAltenHolz from "./pages/ProjectDRKAltenHolz";
import ProjektGOPGluecksburg from "./pages/ProjektGOPGluecksburg";
import ProjektGOPEckernfoerde from "./pages/ProjektGOPEckernfoerde";
import ProjektGOPSurendorf from "./pages/ProjektGOPSurendorf";
import Projectlbpb76 from "./pages/Projectlbpb76";
import ProjectMoekerflaeche from "./pages/ProjectMoekerflaeche";

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AboutUs />}></Route>
        <Route path="/leistungen" element={<Services />}></Route>
        <Route path="/impressum" element={<Imprint />}></Route>
        <Route path="/projekte" element={<Projects />}></Route>
        <Route path="/flintbekkita" element={<ProjectFlintbek />}></Route>
        <Route path="/schulekronshagen" element={<ProjectKronshagen />}></Route>
        <Route path="/drkaltenholz" element={<ProjectDRKAltenHolz />}></Route>
        <Route path="/gopsurendorf" element={<ProjektGOPSurendorf />}></Route>
        <Route path="/moekerflaeche" element={<ProjectMoekerflaeche />}></Route>
        <Route path="/lbpb76" element={<Projectlbpb76 />}></Route>
        <Route
          path="/gopeckernfoerde"
          element={<ProjektGOPEckernfoerde />}
        ></Route>
        <Route
          path="/gopgluecksburg"
          element={<ProjektGOPGluecksburg />}
        ></Route>
        <Route
          path="/kielschlossgarten"
          element={<ProjectSchlossgarten />}
        ></Route>
        <Route
          path="/flintbekschule"
          element={<ProjectFlintbekSchule />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};
