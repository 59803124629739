import React from "react";

import { useNavigate } from "react-router-dom";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const Imprint = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <Header />

      <section>
        <div className="container container--top container-bg2">
          <div className="container-content content--center">
            <div className="container-content">
              <div className="content--center">
                <div className="content-body">
                  <h1>Impressum</h1>
                  <p>Matthiesen und Schlegel GbR</p>
                  <p>Allensteiner Weg 71</p>
                  <p>24161 Altenholz</p>
                  <p>Vertretungsberechtigte Gesellschafter:</p>
                  <p>Bernd Matthiesen und Katrin Schlegel</p>
                  <p>E-Mail: info(at)matthiesen-schlegel.de</p>
                  <p>Telefon: 0431-32 22 54</p>
                  <p>Telefax: 0431-32 37 65</p>
                  <p>Ust. ID-Nr.: DE 12 2200 8208</p>

                  <h2>Ihre Betroffenenrechte</h2>
                  <p>
                    Unter den angegebenen Kontaktdaten unseres
                    Datenschutzbeauftragten können Sie jederzeit folgende Rechte
                    ausüben:
                  </p>
                  <ol>
                    <li>
                      Auskunft über Ihre bei uns gespeicherten Daten und deren
                      Verarbeitung,
                    </li>
                    <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                    <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                    <li>
                      Einschränkung der Datenverarbeitung, sofern wir Ihre Daten
                      aufgrund gesetzlicher Pflichten noch nicht löschen dürfen,
                    </li>
                    <li>
                      Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und
                    </li>
                    <li>
                      Datenübertragbarkeit, sofern Sie in die Datenverarbeitung
                      eingewilligt haben oder einen Vertrag mit uns
                      abgeschlossen haben.
                      <br />
                      Sofern Sie uns eine Einwilligung erteilt haben, können Sie
                      diese jederzeit mit Wirkung für die Zukunft widerrufen.
                    </li>
                  </ol>
                  <p>
                    Sie können sich jederzeit mit einer Beschwerde an die für
                    Sie zuständige Aufsichtsbehörde wenden. Ihre zuständige
                    Aufsichtsbehörde richtet sich nach dem Bundesland Ihres
                    Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung.
                    Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen
                    Bereich) mit Anschrift finden Sie unter:
                    <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                      https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
                    </a>
                  </p>
                  <h2>Haftung für Inhalte</h2>
                  <p>
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für
                    <br />
                    eigene Inhalte auf diesen Seiten nach den allgemeinen
                    Gesetzen verantwortlich.
                    <br />
                    Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
                    nicht
                    <br />
                    verpflichtet, übermittelte oder gespeicherte fremde
                    Informationen zu
                    <br />
                    überwachen oder nach Umständen zu forschen, die auf eine
                    rechtswidrige
                    <br />
                    Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                    Sperrung der
                    <br />
                    Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon
                    <br />
                    unberührt. Eine diesbezügliche Haftung ist jedoch erst ab
                    dem
                    <br />
                    Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                    möglich. Bei
                    <br />
                    Bekanntwerden von entsprechenden Rechtsverletzungen werden
                    wir diese Inhalte
                    <br />
                    umgehend entfernen.
                  </p>
                  <h2>Haftung für Links</h2>
                  <p>
                    Unser Angebot enthält möglicherweise Links zu externen
                    Webseiten Dritter, auf deren
                    <br />
                    Inhalte wir keinen Einfluss haben. Deshalb können wir für
                    diese
                    <br />
                    fremden Inhalte auch keine Gewähr übernehmen. Für die
                    Inhalte
                    <br />
                    der verlinkten Seiten ist stets der jeweilige Anbieter oder
                    Betreiber der
                    <br />
                    Seiten verantwortlich. Die verlinkten Seiten wurden zum
                    Zeitpunkt der Verlinkung
                    <br />
                    auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                    <br />
                    Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                    Eine permanente
                    <br />
                    inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                    konkrete Anhaltspunkte
                    <br />
                    einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                    Rechtsverletzungen
                    <br />
                    werden wir derartige Links umgehend entfernen.
                  </p>
                  <h2>Urheberrecht</h2>
                  <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke
                    auf diesen Seiten
                    <br />
                    unterliegen dem deutschen Urheberrecht. Die
                    Vervielfältigung, Bearbeitung, Verbreitung und
                    <br />
                    jede Art der Verwertung außerhalb der Grenzen des
                    Urheberrechtes bedürfen
                    <br />
                    der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers. Downloads
                    <br />
                    und Kopien dieser Seite sind nur für den privaten, nicht
                    kommerziellen
                    <br />
                    Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
                    nicht vom Betreiber erstellt wurden,
                    <br />
                    werden die Urheberrechte Dritter beachtet. Insbesondere
                    werden Inhalte Dritter als solche
                    <br />
                    gekennzeichnet. Sollten Sie trotzdem auf eine
                    Urheberrechtsverletzung aufmerksam werden, bitten wir um
                    einen entsprechenden Hinweis.
                    <br />
                    Bei Bekanntwerden von Rechtsverletzungen werden wir
                    derartige Inhalte umgehend entfernen.
                  </p>
                  <h2>Kontaktformular</h2>
                  <p>
                    Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder
                    Kontaktformular mit uns in Kontakt, erteilen Sie uns zum
                    Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung.
                    Hierfür ist die Angabe einer validen E-Mail-Adresse
                    erforderlich. Diese dient der Zuordnung der Anfrage und der
                    anschließenden Beantwortung derselben. Die Angabe weiterer
                    Daten ist optional. Die von Ihnen gemachten Angaben werden
                    zum Zwecke der Bearbeitung der Anfrage sowie für mögliche
                    Anschlussfragen gespeichert. Nach Erledigung der von Ihnen
                    gestellten Anfrage werden personenbezogene Daten automatisch
                    gelöscht.
                  </p>
                  <h2>
                    Erfassung allgemeiner Informationen beim Besuch unserer
                    Website
                  </h2>
                  <p>
                    Wenn Sie auf unsere Website zugreifen, werden automatisch
                    mittels eines Cookies Informationen allgemeiner Natur
                    erfasst. Diese Informationen (Server-Logfiles) beinhalten
                    etwa die Art des Webbrowsers, das verwendete Betriebssystem,
                    den Domainnamen Ihres Internet-Service-Providers und
                    ähnliches. Hierbei handelt es sich ausschließlich um
                    Informationen, welche keine Rückschlüsse auf Ihre Person
                    zulassen.
                  </p>
                  <p>
                    Diese Informationen sind technisch notwendig, um von Ihnen
                    angeforderte Inhalte von Webseiten korrekt auszuliefern und
                    fallen bei Nutzung des Internets zwingend an. Sie werden
                    insbesondere zu folgenden Zwecken verarbeitet:
                  </p>
                  <p>
                    Sicherstellung eines problemlosen Verbindungsaufbaus der
                    Website,
                    <br />
                    Sicherstellung einer reibungslosen Nutzung unserer Website,
                    <br />
                    Auswertung der Systemsicherheit und -stabilität sowie
                    <br />
                    zu weiteren administrativen Zwecken.
                    <br />
                    Die Verarbeitung Ihrer personenbezogenen Daten basiert auf
                    unserem berechtigten Interesse aus den vorgenannten Zwecken
                    zur Datenerhebung. Wir verwenden Ihre Daten nicht, um
                    Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten
                    sind nur die verantwortliche Stelle und ggf.
                    Auftragsverarbeiter.
                  </p>
                  <p>
                    Anonyme Informationen dieser Art werden von uns ggfs.
                    statistisch ausgewertet, um unseren Internetauftritt und die
                    dahinterstehende Technik zu optimieren.
                  </p>
                  <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
                  <p>
                    Um unsere Inhalte browserübergreifend korrekt und grafisch
                    ansprechend darzustellen, verwenden wir auf dieser Website
                    Scriptbibliotheken und Schriftbibliotheken wie z. B. Google
                    Webfonts (https://www.google.com/webfonts/). Google Webfonts
                    werden zur Vermeidung mehrfachen Ladens in den Cache Ihres
                    Browsers übertragen. Falls der Browser die Google Webfonts
                    nicht unterstützt oder den Zugriff unterbindet, werden
                    Inhalte in einer Standardschrift angezeigt.
                  </p>
                  <p>
                    Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken
                    löst automatisch eine Verbindung zum Betreiber der
                    Bibliothek aus. Dabei ist es theoretisch möglich – aktuell
                    allerdings auch unklar ob und ggf. zu welchen Zwecken – dass
                    Betreiber entsprechender Bibliotheken Daten erheben.
                  </p>
                  <p>
                    Die Datenschutzrichtlinie des Bibliothekbetreibers Google
                    finden Sie hier:
                    <a href="https://web.archive.org/web/20240528172452/https://www.google.com/policies/privacy/">
                      https://www.google.com/policies/privacy/
                    </a>
                  </p>
                  <h2>Fragen&nbsp;zum Datenschutz</h2>
                  <p>
                    Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns
                    bitte eine E-Mail.
                  </p>
                  <hr />
                  <blockquote>
                    <em>
                      Die Datenschutzerklärung wurde&nbsp;unter Zuhilfename des
                      Datenschutzerklärungs-Generator der activeMind AG
                      erstellt.
                    </em>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default React.memo(Imprint);
