import React from "react";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

import projectData from "./projects/gopeckernfoerde.json";

const ProjektGOPEckernfoerde = () => {
  return (
    <>
      <Header active="projects" />

      <section className="container container--top container--bottom container-bg2">
        <div className="container-content content--center">
          <article
            className="content-body"
            dangerouslySetInnerHTML={{ __html: projectData.description }}
          />

          <div className="divider">
            <div className="divider-symbol  container-bg2"></div>
          </div>
        </div>
      </section>

      <section className="container-bg2">
        <div className="flexslider-container container-bg2">
          <div className="flexslider">
            <ul className="slides">
              {projectData.examples.map((item, idx) => (
                <li key={idx}>
                  <img src={"images/projects/" + item.filename} />
                  <div className="slide_info container-bg1 paper">
                    <p>{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <div className="lightbox"></div>

      <Footer />
    </>
  );
};

export default React.memo(ProjektGOPEckernfoerde);
