import React from "react";

import { useNavigate } from "react-router-dom";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const Projects = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <Header active="projects" />
      <div className="container container-bg2 container--top">
        <div className="container-content">
          <div className="content--center">
            <h1 className="content-title">Projektbeispiele Freiraumplanung</h1>
            <div className="content-body">
              <br />

              <div className="projekt-item">
                <img
                  src="images/projects/flintbekkita.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    Gemeinde Flintbek – Außenanlagen der neuen KiTA Dickskamp
                  </h2>
                  <p>
                    Die für drei Elementar- und drei Krippengruppen vorgesehene
                    modern gestaltete Kindertagesstätte der Gemeinde Flintbek
                    liegt eingebettet in hängiges Gelände, das zu
                    Höhenunterschieden von bis zu 2 m im Nahbereich der Terrasse
                    führt.
                  </p>
                  <a href="flintbekkita" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/teaser_schulekronshagen.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    Gemeinde Kronshagen – Schulhofgestaltung der
                    Brüder-Grimm-Schule
                  </h2>
                  <p>
                    Der Schulhof vor der denkmalgeschützten Kronshagener
                    Grundschule benötigte eine Auffrischung, um den Kindern
                    zeitgemäße Bewegungsanreize, Spielangebote und originelle
                    Aufenthaltsplätze zu bieten.
                  </p>
                  <a href="schulekronshagen" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/teaser_flintbek.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>Gemeinde Flintbek - Schule am Eiderwald</h2>
                  <p>
                    Der Schulhof bot außer einem Bolzplatz bisher wenig
                    Möglichkeiten zur aktiven Pausengestaltung. Im Rahmen eine
                    Workshops mit allen Schülern wurden zunächst die Bedürfnisse
                    und Wünsche erarbeitet.
                  </p>
                  <a href="flintbekschule" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/kielschlossgarten.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    Landeshauptstadt Kiel – Prachtstaudenbeet im Schlossgarten
                  </h2>
                  <p>
                    Der Jahrzehnte brachliegende und nun neu belebte
                    Schlossgartenbereich nahe der Kieler Kunsthalle erhielt
                    neben einer zentralen Treppenanlage auch eine barrierefreie
                    Zuwegung zum oberen Plateau.
                  </p>
                  <a href="kielschlossgarten" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/drkaltenholz.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>DRK-Seniorenwohnheim Altenholz</h2>
                  <p>
                    Die großzügige Außenanlage des neuen DRK-Seniorenwohnheims
                    in Altenholz-Klausdorf bietet den Bewohnern einen
                    attraktiven und gefahrlos zu nutzenden Freiraum.
                  </p>
                  <a href="drkaltenholz" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <a name="landschaftsplanung"></a>
            </div>
          </div>

          <div className="content--center">
            <h1 className="content-title">
              Projektbeispiele Landschaftsplanung
            </h1>
            <div className="content-body">
              <div className="projekt-item">
                <img
                  src="/images/projects/gopgluecksburg.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    Stadt Glücksburg / Ostsee – Einrichtung eines Ruheforstes
                  </h2>
                  <p>
                    Die Bestattung außerhalb des üblichen Rahmens, z. B. in
                    einem schönen Wald, gewinnt an Bedeutung.
                  </p>
                  <a href="gopgluecksburg" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/gopeckernfoerde.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>Stadt Eckernförde – Baugebiet Langwühr / Schiefkoppel</h2>
                  <p>
                    Bei einer guten planerischen und zukunftsorientierten
                    Vorarbeit seitens der Gemeinde oder Stadt können auch große
                    Bauvorhaben schnell im Detail planerisch zu Papier und auf
                    den Weg gebracht werden, wie z. B. das wohl letzte große
                    Wohngebiet in der Ostseestadt Eckernförde.
                  </p>
                  <a href="gopeckernfoerde" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/gopsurendorf.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    Grünordnungsplanung Campingplätze in der Gemeinde
                    Schwedeneck
                  </h2>
                  <p>
                    Feste Campinghäuser stellen neuerdings auch in
                    Schleswig-Holstein eine beliebte Alternative zu Wohnwagen
                    und Zelt dar.
                  </p>
                  <a href="gopsurendorf" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/teaser_lbpb76.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    B76 – Vierstreifiger Ausbau von der Karlshöhe bis zum
                    Lornsenplatz in Eckernförde
                  </h2>
                  <p>
                    Umweltbaubegleitung – Hinter diesem sperrigen Begriff
                    verbirgt sich die Begleitung und Überwachung von Bauvorhaben
                    hinsichtlich der Umsetzung von Maßnahmen zugunsten von Natur
                    und Landschaft.
                  </p>
                  <a href="lbpb76" className="button">
                    mehr Details
                  </a>
                </div>
              </div>

              <div className="projekt-item">
                <img
                  src="/images/projects/moekerflaeche.jpg"
                  className="projekt-item-visual"
                />

                <div className="projekt-item-text clearfix">
                  <h2>
                    Landeshauptstadt Kiel – Kleingewässerherstellung und
                    Fließgewässerentrohrung
                  </h2>
                  <p>
                    In der sog. Sammelersatzmaßnahme Rönne im gleichnamigen
                    Kieler Stadtteil im ländlichen Süden der Stadt entstehen
                    vielfältige Landschaftselemente und Biotope, die für den
                    Ausgleich von Eingriffen in Natur und Landschaft an anderer
                    Stelle sorgen sollen.
                  </p>
                  <a href="moekerflaeche" className="button">
                    mehr Details
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default React.memo(Projects);
