import React from "react";

import { useNavigate } from "react-router-dom";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const AboutUs = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <Header active="aboutus" />

      <section>
        <div className="teaser teaser-2 container-bg2 teaser--top">
          <h1
            className="teaser-title teaser-title--hidden"
            style={{ opacity: "1" }}
          >
            "Flexibel und mit kreativen Lösungsansätzen unterstützen wir Sie"
          </h1>
        </div>

        <div className="container container-bg2">
          <div className="container-content content--center">
            <article className="content-body">
              <h1>
                Freiraum- und Landschaftsplanung
                <br />
                Matthiesen · Schlegel
              </h1>
              <p>
                Flexibel und mit kreativen Lösungsansätzen bearbeiten wir mit
                unserem Team seit Jahren Aufträge aus dem gesamten
                Aufgabenspektrum der Landschaftsarchitektur.
              </p>
              <p>
                Gärten, Spiel-, Sport- und Golfplätze, Biotope, Parks und
                Stadtplätze gehören wie alle weiteren denkbaren Außenanlagen zu
                unserem Aufgabenbereich als Landschaftsarchitekten.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <p>
                Aber wir planen nicht nur Freiraumobjekte, sondern entwerfen
                auch Konzepte für das Grün in Baugebieten, Ortsteilen,
                Verkehrsanlagen, Betrieben und ganzen Gemeinden. Die Pläne
                heißen dann Grünordnungsplan und Landschaftspflegerischer
                Begleitplan, Landschaftsplan oder Umweltverträglichkeitsstudie.
              </p>
            </article>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="container-content">
            <article className="content-body">
              <div className="container-profil">
                <h2>
                  Bernd Matthiesen
                  <span className="profil-title-job">
                    Dipl.-Ing. Landschaftsarchitekt
                  </span>
                </h2>
                <p>
                  Zuständig für Landschaftspläne, Grünordnungspläne und
                  Landschaftspflegerische Begleitpläne, Begrünungskonzepte für
                  Baugebiete und die Planung von Ausgleichsmaßnahmen für
                  Eingriffe in Natur und Landschaft. Sowie für
                  Landschaftsökologische und raumanalytische Gutachten z. B. im
                  Rahmen von Umweltverträglichkeitsstudien.
                </p>
              </div>

              <div className="container-profil">
                <h2>
                  Katrin Schlegel
                  <span className="profil-title-job">
                    Dipl.-Ing. Landschaftsarchitektin
                  </span>
                </h2>
                <p>
                  Ansprechpartnerin für die Freiraumplanung. Diese umfasst das
                  gesamte Spektrum der Planung und Bauleitung von Außenanlagen -
                  von Privatgärten über Freiflächen von Wohnanlagen und
                  Gewerbeobjekten bis zu öffentlichen Parks, Sport- und
                  Spielplätzen.
                </p>
              </div>
            </article>
            <p className="button-center">
              <a href="leistungen" className="button">
                Erfahren Sie, was wir für Sie tun können
              </a>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default React.memo(AboutUs);
