import React from "react";

import { useNavigate } from "react-router-dom";
import Footer from "../navigation/Footer";
import Header from "../navigation/Header";

const Services = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/");
  };

  return (
    <>
      <Header active="services" />

      <section>
        <div className="teaser teaser-4 container-bg2 teaser--top">
          <ul className="teaser-items">
            <li style={{ marginTop: "0px", opacity: "1" }}>
              Erfassung
              <br />
              und
              <br />
              Bewertung
            </li>
            <li style={{ marginTop: "0px", opacity: "1" }}>
              Planung
              <br />
              und
              <br />
              Koordinierung
            </li>
            <li style={{ marginTop: "0px", opacity: "1" }}>
              Projektbegleitung
              <br />
              und
              <br />
              Bauüberwachung
            </li>
          </ul>
        </div>

        <div className="container container-bg2">
          <div className="container-content content--center">
            <div className="content-body">
              <h1>Freiraum- und Landschaftsplanung auf den Punkt gebracht …</h1>
              <h2>
                … umfasst diese drei Bereiche bei allen Vorhaben im Freien,
                besonders wenn Umwelt- und Grünbelange zu beachten sind.
              </h2>
              <p>
                Mensch und Natur stehen im Mittelpunkt unserer Planung, wenn
                private und öffentliche Grünanlagen, Dorf- und Stadtplätze,
                Sport- und Spielflächen entstehen. Unsere Entwürfe sind auf
                Funktionalität und Benutzerfreundlichkeit ausgerichtet und
                punkten durch Kreativität und Ästhetik.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <p>
                In der Grün- und Landschaftsplanung stehen wir für pragmatische
                Lösungsansätze und können durch unsere gute Vernetzung bei
                besonderen Fragestellungen auf ein eingespieltes Team mit
                Spezialkenntnissen zurückgreifen.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <p>
                Mit unserem reichen Erfahrungsschatz aus dem gesamten Spektrum
                der Landschaftsarchitektur unterstützen wir Sie gern bei all
                Ihren Projekten.
              </p>
            </div>

            <p className="button-center">
              <a
                href="#leistungen2"
                className="button-round"
                onClick={() => {
                  jq_scrollToItem("#leistungen2");
                  return false;
                }}
              >
                Mehr erfahren
              </a>
            </p>
          </div>
        </div>
      </section>
      <section id="leistungen2">
        <div className="teaser teaser-3 container-bg2">
          <h1 className="teaser-title">
            <br />
            "Am Anfang steht immer der Plan ..."
          </h1>
        </div>

        <div className="container container-bg2">
          <div className="container-content content--center">
            <article className="content-body">
              <h1>Freiraumplanung- und Landschaftsplanung</h1>
              <p>
                Wir planen großflächig in Form von
                <strong>Landschafts- oder Flächennutzungsplänen</strong>, die
                als Geltungsbereich das gesamte Gemeindegebiet umfassen. Größere
                Landschaftsausschnitte werden auch im Rahmen von
                <strong>Umweltverträglichkeitsstudien</strong> für
                Verkehrsvorhaben untersucht.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <p>
                <strong>Landschaftspflegerische Begleitpläne</strong> können
                mehrere Hektar umfassende Untersuchungsbereiche abdecken, wenn
                z. B. ein kilometerlanger Radwegebau naturschutzrechtlich
                behandelt wird. Dagegen ist im Rahmen der
                landschaftspflegerischen Begleitplanung für eine Reithalle im so
                genannten Außenbereich lediglich ein kleines Areal zu
                betrachten.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <p>
                <strong>Grünordnungspläne</strong> werden in der Regel im
                Maßstab 1 : 1.000 erstellt und decken nur Teile des
                Gemeindegebietes ab. Sie sind eine besondere Form der
                Landschaftspflegerischen Begleitplanung, die bei neuen,
                planungsrechtlich durch Bebauungspläne vorbereiteten Baugebieten
                erforderlich werden. Sie haben zumeist einen mindestens zwei
                Hektar großen Geltungsbereich. Eine Ausnahme ist beispielsweise
                der von uns bearbeitete Grünordnungsplan zur
                Golfplatzerweiterung in Glücksburg/O. mit einem Geltungsbereich
                von über 40 ha.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <p>
                Noch detaillierter wird die Planung, wenn die Freiflächen eines
                Baugebietes oder die Außenanlagen eines neu erschlossenen
                Grundstückes von uns Landschaftsarchitekten gestaltet werden
                sollen. Dafür werden von uns Höhen- und Lagepläne sowie
                Entwurfs- und Bepflanzungspläne erstellt, die nach der
                Ausschreibung der beauftragten Firma als Grundlage für die
                Ausführung dienen. Unsere Bauleitung stellt in dieser Phase den
                reibungslosen Bauablauf und eine fachgerechte Leistung durch die
                ausführende Firma sicher.
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <ul className="services-list">
                <li>Landschaftsplan</li>
                <li>Landschaftspflegerischer Begleitplan</li>
                <li>Grünordnungsplan</li>
                <li>Biotopkartierung und Biotoptypenkartierung</li>
                <li>Umweltverträglichkeitsstudie</li>
                <li>Naturschutzfachplan</li>
                <li>Pflege- und Entwicklungsplan</li>
              </ul>
              <p class="button-center">
                <a href="/projekte#landschaftsplanung" class="button">
                  Beispiele Landschaftsplanung
                </a>
              </p>
              <div className="divider">
                <div className="divider-symbol container-bg2"></div>
              </div>
              <ul className="services-list">
                <li>Planung öffentlicher Grünanlagen</li>
                <li>Platzgestaltung</li>
                <li>Sportplatzplanung</li>
                <li>Spielplatzplanung</li>
                <li>Gartenplanung</li>
                <li>Stauden-Pflanzplanung</li>
                <li>Erstellung von Grünflächen-Pflegeplänen</li>
                <li>Landschaftspflegerische Ausführungsplanung</li>
                <li>Planung ökologischer Maßnahmen</li>
              </ul>
              <p class="button-center">
                <a href="/projekte" class="button">
                  Beispiele Freiraumplanung
                </a>
              </p>
            </article>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default React.memo(Services);
